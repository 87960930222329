import { useMemo } from 'react';
import dynamicAxios from 'utils/axios';
import { Service } from './baseService';
import { ENDPOINTS } from 'constants/api';
import useAuth from 'hooks/useAuth';
import { Id } from '@restworld/data-services/src/lib/utils/common_types';
import { Note, RWMember, Restaurant, User, Variable } from '@restworld/utility-types';
import {
  EmployerFilterType,
  IndexStripePaymentMethods,
  IndexStripeSubscriptions
} from '@restworld/data-services';

export type SecondaryContact = {
  id: number;
  user_id?: string;
  user?: UpdatedUser;
  name?: string;
  surname?: string;
  phone_number?: string;

  inserted_at: string;
  updated_at: string;
};

export type UpdatedUser = User & {
  name?: string;
  surname?: string;
  secondary_contacts?: SecondaryContact[];
};

export type EmployerUserContact = {
  user_id: string;
  user?: UpdatedUser;
  preferred_communication_channel_id?: string;
  preferred_communication_channel_var?: Variable;
  inactive?: boolean;
  role?: string;

  inserted_at: string;
  updated_at: string;
};

export type Organization = {
  id: string;
  name: string;
  assigned_to?: string;
  assigned_to_rw_member?: RWMember;
  created_by?: string;
  created_by_rw_member?: RWMember;
  notes?: Note[];
  employer_user_contacts?: EmployerUserContact[];
  restaurants?: RestaurantUpdated[];
  companies?: Company[];

  inserted_at: string;
  updated_at: string;
};

export type Company = {
  id: string;
  name?: string;
  vat_number?: string;
  sdi_code?: string;
  source?: string;
  source_var?: Variable;
  local_partner_name?: string;
  fiscal_code?: string;
  billing_email_address?: string;

  city?: string;
  registered_office?: string;
  country_code?: string;
  province_code?: string;
  street_name?: string;
  house_number?: string;
  zip_code?: string;

  finom_customer_id?: string;
  fic_client_id?: string;
  stripe_customer_id?: string;
  created_by?: string;
  created_by_rw_member?: RWMember;

  // restaurants?: Array<Restaurant>;

  inserted_at: string;
  updated_at: string;
};

export type IndexOrganizations = {
  hits: number;
  data: {
    organization: Organization;
    number_of_job_positions: number;
    number_of_restaurants: number;
  }[];
};
export type RestaurantUpdated = Restaurant & {
  referent_user_contacts?: EmployerUserContact[];
  organization_id?: string;
  organization?: Organization;
};

export type CreateOrganization = {
  organization_id: string;
  company_id: string;
};

class ServiceAccounts extends Service {
  indexOrganizations({
    limit,
    offset,
    order_by,
    filters
  }: {
    limit: number;
    offset: number;
    order_by?: string[];
    filters?: EmployerFilterType;
  }) {
    return dynamicAxios(this.jwt).get<IndexOrganizations>(ENDPOINTS.organizations.root, {
      params: { limit, offset, order_by, ...filters }
    });
  }

  createOrganization(data: Partial<Organization>) {
    return dynamicAxios(this.jwt).post<CreateOrganization>(ENDPOINTS.organizations.root, data);
  }

  updateOrganization(data: Partial<Organization>) {
    return dynamicAxios(this.jwt).patch<Id>(`${ENDPOINTS.organizations.root}/${data.id}`, data);
  }

  showOrganization(organizationId: string, context: string) {
    return dynamicAxios(this.jwt).get<Organization>(
      `${ENDPOINTS.organizations.root}/${organizationId}`,
      { params: { context } }
    );
  }

  fetchCompanies({
    limit,
    offset,
    organizationId,
    context
  }: {
    context: string;
    limit: number;
    offset: number;
    organizationId: string;
  }) {
    return dynamicAxios(this.jwt).get<Company[]>(ENDPOINTS.companies.root, {
      params: { organization_id: organizationId, limit, offset, context }
    });
  }

  createCompany(data: Partial<Company>) {
    return dynamicAxios(this.jwt).post<Id>(ENDPOINTS.companies.root, data);
  }

  updateCompany(data: Partial<Company>) {
    return dynamicAxios(this.jwt).patch<Id>(`${ENDPOINTS.companies.root}/${data.id}`, data);
  }

  fetchStripeSubscriptions({
    companyId,
    organizationId
  }: {
    companyId?: string;
    organizationId?: string;
  }) {
    return dynamicAxios(this.jwt).get<IndexStripeSubscriptions>(ENDPOINTS.companies.subscriptions, {
      params: { company_id: companyId, organization_id: organizationId }
    });
  }

  fetchStripePaymentMethods({
    organizationId,
    companyId
  }: {
    organizationId?: string;
    companyId?: string;
  }) {
    return dynamicAxios(this.jwt).get<IndexStripePaymentMethods>(
      ENDPOINTS.organizations.paymentMethods,
      {
        params: { organization_id: organizationId, company_id: companyId }
      }
    );
  }

  sendSubscriptionEmail({ companyId, paymentLink }: { companyId?: string; paymentLink?: string }) {
    return dynamicAxios(this.jwt).post<string>(ENDPOINTS.companies.subscriptionEmail, {
      company_id: companyId,
      payment_link: paymentLink
    });
  }

  deleteCompany(companyId?: string) {
    return dynamicAxios(this.jwt).delete<string>(`${ENDPOINTS.companies.root}/${companyId}`);
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceAccounts(jwt), [jwt]);
};
export default useService;
